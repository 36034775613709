body::backdrop {
  --customState: paused;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-image: url('lovecomp1.png');
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: 0 0;
  animation:background-infinite 90s linear infinite;
  animation-play-state: var(--customState);
  height: 100%;
  width: 100%;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(34, 31, 31, 0.95)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes background-infinite {
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: -2750px;
  }
}

@-webkit-keyframes background-infinite {
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: -2750px;
  }
}

@-o-viewport {
  width: device-width;
  height: device-height;
  zoom: 1.0;
}

@media screen and (max-width: 45em) {

  body {
    animation: background-infinite 110s linear infinite
  }
}